/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from '@/axios.js'

export default {
  addReferralCode ({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.post('/api/backoffice/referral-codes', item)
        .then((response) => {
          commit('ADD_REFERRAL_CODE', Object.assign(item, {id: response.data.id}))
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchReferralCodes ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/backoffice/referral-codes')
        .then((response) => {
          commit('SET_REFERRAL_CODES', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateReferralCode ({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.put(`/api/backoffice/referral-codes/${item.id}`, item)
        .then((response) => {
          commit('UPDATE_REFERRAL_CODE', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchUtmTagBonuses ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/backoffice/utm-tags-for-bonuses')
        .then((response) => {
          commit('SET_UTM_TAG_BONUS', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  addUtmTagBonus ({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.post('/api/backoffice/utm-tags-for-bonuses', item)
        .then((response) => {
          commit('ADD_UTM_TAG_BONUS', response.data);
          resolve(response);
        })
        .catch((error) => { reject(error) })
    })
  },
  deleteUtmTagBonus ({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/backoffice/utm-tags-for-bonuses/${item.id}`)
        .then((response) => {
          commit('REMOVE_UTM_TAG_BONUS', item);
          resolve(response);
        })
        .catch((error) => { reject(error) })
    })
  }
}

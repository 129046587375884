import auth0 from 'auth0-js'
import EventEmitter from 'events'
import authConfig from '@/../auth_config.json'
import jwt_decode from "jwt-decode";

import store from '@/store/store.js'

// 'loggedIn' is used in other parts of application. So, Don't forget to change there also
const localStorageKey = 'loggedIn'

const tokenExpiryKey = 'tokenExpiry'
const loginEvent = 'loginEvent'
const accessTokenKey = 'accessToken'

const webAuth = new auth0.WebAuth({
  domain: authConfig.domain,
  redirectUri: `${window.location.origin}/auth0/callback`,
  clientID: authConfig.clientId,
  audience: authConfig.audience,
  responseType: 'id_token token',
  scope: 'openid profile email'
})

class AuthService extends EventEmitter {
    idToken = null;
    profile = null;
    tokenExpiry = null;

    // Starts the user login flow
    login (customState, provider = '') {
      webAuth.authorize({
        connection: provider,
        appState: customState
      })
    }

    // Handles the callback request from Auth0
    handleAuthentication () {
      return new Promise((resolve, reject) => {
        webAuth.parseHash((err, authResult) => {
          if (err) {
            window.location.href = '/'
            // alert(`${err.error}. Detailed error can be found in console.`)
            reject(err)
          } else {
            this.localLogin(authResult)
            resolve(authResult.idToken)
          }
        })
      })
    }

    localLogin (authResult) {
      this.idToken = authResult.idToken
      this.profile = authResult.idTokenPayload

      // Convert the JWT expiry time from seconds to milliseconds
      this.tokenExpiry = new Date(this.profile.exp * 1000)
      localStorage.setItem(tokenExpiryKey, this.tokenExpiry)
      localStorage.setItem(localStorageKey, 'true')
      localStorage.setItem(accessTokenKey, authResult.accessToken)
      let userRole = this.getUserRole(authResult.accessToken)

      store.commit('UPDATE_USER_INFO', {
        displayName: this.profile.name,
        email: this.profile.email,
        photoURL: this.profile.picture,
        providerId: this.profile.sub.substr(0, this.profile.sub.indexOf('|')),
        uid: this.profile.sub,
        userRole: userRole
      })

      this.emit(loginEvent, {
        loggedIn: true,
        profile: authResult.idTokenPayload,
        state: authResult.appState || {},
        userRole: userRole
      })
    }

    renewTokens () {
      // reject can be used as parameter in promise for using reject
      return new Promise((resolve) => {
        if (localStorage.getItem(localStorageKey) !== 'true') {
          // return reject("Not logged in");
        }

        webAuth.checkSession({}, (err, authResult) => {
          if (err) {
            // reject(err);
          } else {
            this.localLogin(authResult)
            resolve(authResult)
          }
        })
      })
    }

    logOut () {
      this.resetLocalStorage()

      this.idToken = null
      this.tokenExpiry = null
      this.profile = null

      webAuth.logout({
        returnTo: window.location.origin
      })

      this.emit(loginEvent, { loggedIn: false })
    }

    resetLocalStorage() {
      localStorage.removeItem(localStorageKey)
      localStorage.removeItem(tokenExpiryKey)
      localStorage.removeItem('userInfo')
      localStorage.removeItem(accessTokenKey)
    }

    isAuthenticated () {
      return (
        new Date(Date.now()) < new Date(localStorage.getItem(tokenExpiryKey)) &&
            localStorage.getItem(localStorageKey) === 'true'
      )
    }

    getUserRole(accessToken) {
      let accessTokenData = jwt_decode(accessToken)
      let userRole = ['investor']

      if (accessTokenData.permissions) {
        if (~accessTokenData.permissions.indexOf('R_ADMIN')) {
          userRole.push('RULE_ADMIN')
        }
        if (~accessTokenData.permissions.indexOf('R_BACKOFFICE')) {
          userRole.push('RULE_BACKOFFICE')
        }
        if (~accessTokenData.permissions.indexOf('R_BANK')) {
          userRole.push('RULE_BANK')
        }
        if (~accessTokenData.permissions.indexOf('R_COMPLIANCE')) {
          userRole.push('RULE_COMPLIANCE')
        }
        if (~accessTokenData.permissions.indexOf('R_FINANCE')) {
          userRole.push('RULE_FINANCE')
        }
        if (~accessTokenData.permissions.indexOf('R_KYC')) {
          userRole.push('RULE_KYC')
        }
        if (~accessTokenData.permissions.indexOf('R_LO_MANAGER')) {
          userRole.push('RULE_LO_MANAGER')
        }
        if (~accessTokenData.permissions.indexOf('R_MARKETING')) {
          userRole.push('RULE_MARKETING')
        }
        if (~accessTokenData.permissions.indexOf('R_REPORTS')) {
          userRole.push('RULE_REPORTS')
        }
        if (~accessTokenData.permissions.indexOf('R_FIN_CONFIRM')) {
          userRole.push('RULE_FINANCE_CONFIRM')
        }
      }
      return userRole
    }
}

export default new AuthService()

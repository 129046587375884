import Vue from 'vue'
import {AclCreate, AclInstaller, AclRule} from 'vue-acl'
import router from '@/router'

Vue.use(AclInstaller)

let initialRole = 'investor'

const userInfo = JSON.parse(localStorage.getItem('userInfo'))
if (userInfo && userInfo.userRole)
{
  initialRole = userInfo.userRole
  //console.log('XXXXXXXXXXXXXXXXXXX', userInfo)
}

export default new AclCreate({
  initial: initialRole,
  notfound: '/pages/not-authorized',
  router,
  acceptLocalRules: true,
  globalRules: {
    RULE_ADMIN: new AclRule('RULE_ADMIN').generate(),
    RULE_BACKOFFICE: new AclRule('RULE_BACKOFFICE').generate(),
    RULE_BANK: new AclRule('RULE_BANK').generate(),
    RULE_COMPLIANCE: new AclRule('RULE_COMPLIANCE').generate(),
    RULE_FINANCE: new AclRule('RULE_FINANCE').generate(),
    RULE_KYC: new AclRule('RULE_KYC').generate(),
    RULE_LO_MANAGER: new AclRule('RULE_LO_MANAGER').generate(),
    RULE_MARKETING: new AclRule('RULE_MARKETING').generate(),
    RULE_REPORTS: new AclRule('RULE_REPORTS').generate(),
    RULE_FINANCE_CONFIRM: new AclRule('RULE_FINANCE_CONFIRM').generate(),

    RULE_SOME: new AclRule('RULE_ADMIN')
      .or('RULE_BACKOFFICE')
      .or('RULE_BANK')
      .or('RULE_COMPLIANCE')
      .or('RULE_FINANCE')
      .or('RULE_KYC')
      .or('RULE_LO_MANAGER')
      .or('RULE_MARKETING')
      .or('RULE_REPORTS')
      .or('RULE_FINANCE_CONFIRM')
      .generate(),
  }
})

/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Vuex from 'vuex'

import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

Vue.use(Vuex)

import moduleTodo from './todo/moduleTodo.js'
import moduleCalendar from './calendar/moduleCalendar.js'
import moduleChat from './chat/moduleChat.js'
import moduleEmail from './email/moduleEmail.js'
import moduleAuth from './auth/moduleAuth.js'
import moduleECommerce from './eCommerce/moduleECommerce.js'
import moduleLoanOriginators from "@/store/loanOriginators/moduleLoanOriginators";
import moduleInvestorManagement from "@/store/investor/moduleInvestorManagement";
import moduleProductManagement from "@/store/product/moduleProductManagement";
import moduleUserManagement from "@/store/user-management/moduleUserManagement";
import moduleDataList from "@/store/data-list/moduleDataList";
import classificatorManagement from "@/store/classificator/classificatorManagement";
import moduleReferralCodeManagement from "./referralCode/moduleReferralCodeManagement";

export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    todo: moduleTodo,
    calendar: moduleCalendar,
    chat: moduleChat,
    email: moduleEmail,
    auth: moduleAuth,
    eCommerce: moduleECommerce,
    loanOriginators: moduleLoanOriginators,
    investorManagement: moduleInvestorManagement,
    productManagement: moduleProductManagement,
    referralCodeManagement: moduleReferralCodeManagement,
    userManagement: moduleUserManagement,
    dataList: moduleDataList,
    classificatorManagement: classificatorManagement
  },
  strict: process.env.NODE_ENV !== 'production'
})

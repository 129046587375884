/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
                    path => router path
                    name => router name
                    component(lazy loading) => component to load
                    meta : {
                      rule => which user can have access (ACL)
                      breadcrumb => Add breadcrumb to specific page
                      pageTitle => Display title besides breadcrumb
                    }
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Router from 'vue-router'
import auth from '@/auth/authService'
import {AclRule} from 'vue-acl'

import firebase from 'firebase/app'
import 'firebase/auth'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: '/',
  scrollBehavior()
  {
    return {x: 0, y: 0}
  },
  routes: [

    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: '',
      component: () => import('./layouts/main/Main.vue'),
      meta: {
        rule: 'investor',
        authRequired: true
      },
      children: [
        {
          path: '/',
          name: 'home',
          component: () => import('./views/Dashboard.vue'),
          meta: {
            rule: new AclRule('RULE_SOME').generate(),
            authRequired: true
          }
        },
        {
          path: '/investors',
          name: 'investors',
          component: () => import('@/views/apps/investor/InvestorList.vue'),
          meta: {
            rule: new AclRule('RULE_ADMIN').or('RULE_BACKOFFICE').generate(),
            authRequired: true
          }
        },
        {
          path: '/investor/investor-view/:id',
          name: 'investor-view',
          component: () => import('@/views/apps/investor/InvestorView.vue'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Investors', url: '/investors'},
              {title: 'View', active: true}
            ],
            pageTitle: 'Investor View',
            rule: new AclRule('RULE_ADMIN').or('RULE_BACKOFFICE').generate(),
            authRequired: true
          }
        },
        {
          path: '/investors/new',
          name: 'investor-new',
          component: () => import('@/views/apps/investor/InvestorEdit.vue'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Investors', url: 'investors'},
              {title: 'New', active: true}
            ],
            pageTitle: 'Investor New',
            rule: new AclRule('RULE_ADMIN').or('RULE_BACKOFFICE').generate(),
            authRequired: true
          }
        },
        {
          path: '/products',
          name: 'products',
          component: () => import('@/views/apps/product/product-list/ProductList.vue'),
          meta: {
            rule: new AclRule('RULE_ADMIN').generate(),
            authRequired: true
          }
        },
        {
          path: '/referral-codes',
          name: 'referral-code',
          component: () => import('@/views/apps/referralCode/referral-code/ReferralCode.vue'),
          meta: {
            rule: new AclRule('RULE_ADMIN').generate(),
            authRequired: true
          }
        },
        {
          path: '/access-logs',
          name: 'access-logs',
          component: () => import('@/views/apps/access-logs/AccessLogs.vue'),
          meta: {
            rule: new AclRule('RULE_ADMIN').or('RULE_COMPLIANCE').generate(),
            authRequired: true
          }
        },
        {
          path: '/loans',
          name: 'loans',
          component: () => import('@/views/apps/loan/loan-list/LoanList.vue'),
          meta: {
            rule: new AclRule('RULE_ADMIN').or('RULE_BACKOFFICE').generate(),
            authRequired: true
          }
        },
        {
          path: '/investments',
          name: 'investments',
          component: () => import('@/views/apps/investment/InvestmentList.vue'),
          meta: {
            rule: new AclRule('RULE_ADMIN').or('RULE_BACKOFFICE').generate(),
            authRequired: true
          }
        },
        {
          path: '/loan/:id',
          name: 'loanView',
          component: () => import('@/views/apps/loan/LoanView.vue'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Loans', url: '/loans'},
              {title: 'View', active: true}
            ],
            pageTitle: 'Loan details',
            rule: new AclRule('RULE_ADMIN').or('RULE_BACKOFFICE').generate(),
            authRequired: true
          }
        },
        {
          path: '/loanAdd/:loan_originator_id',
          name: 'loanAdd',
          component: () => import('@/views/apps/loan/LoanEdit.vue'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Loans', url: '/loans'},
              {title: 'Edit', active: true}
            ],
            pageTitle: 'Edit loan',
            rule: new AclRule('RULE_ADMIN').generate(),
            authRequired: true
          }
        },
        {
          path: '/loanEdit/:id',
          name: 'loanEdit',
          component: () => import('@/views/apps/loan/LoanEdit.vue'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Loans', url: '/loans'},
              {title: 'Edit', active: true}
            ],
            pageTitle: 'Edit loan',
            rule: new AclRule('RULE_ADMIN').generate(),
            authRequired: true
          }
        },
        {
          path: '/bankImportMain',
          name: 'bankImportMain',
          component: () => import('@/views/apps/bankImport/BankImportMain.vue'),
          meta: {
            rule: new AclRule('RULE_ADMIN').or('RULE_BANK').generate(),
            authRequired: true
          }
        },
        {
          path: '/bankImportShow/:importFileId',
          name: 'bankImportShow',
          component: () => import('@/views/apps/bankImport/BankImportShow.vue'),
          meta: {
            rule: new AclRule('RULE_ADMIN').or('RULE_BANK').generate(),
            authRequired: true
          }
        },
        {
          path: '/bankImportParse/:importFileId',
          name: 'bankImportParse',
          component: () => import('@/views/apps/bankImport/BankImportShow.vue'),
          meta: {
            rule: new AclRule('RULE_ADMIN').or('RULE_BANK').generate(),
            authRequired: true
          }
        },
        {
          path: '/treasury-clearing',
          name: 'treasuryClearing',
          component: () => import('@/views/apps/treasuryClearing/TreasuryClearing.vue'),
          meta: {
            rule: new AclRule('RULE_ADMIN').or('RULE_FINANCE').generate(),
            authRequired: true
          }
        },
        {
          path: 'management/asset',
          name: 'Asset',
          component: () => import('@/views/apps/asset/Asset.vue'),
          meta: {
            rule: new AclRule('RULE_ADMIN').or('RULE_REPORTS').generate(),
            authRequired: true
          }
        },
        {
          path: 'account-statement',
          name: 'account-statement',
          component: () => import('@/views/apps/accountStatement/AccountStatement.vue'),
          meta: {
            rule: new AclRule('RULE_ADMIN').or('RULE_FINANCE').generate(),
            authRequired: true
          }
        },
        {
          path: 'lo-monitoring-reports',
          name: 'LO Monitoring',
          component: () => import('@/views/apps/lo/MonitoringReports.vue'),
          meta: {
            rule: new AclRule('RULE_ADMIN').or('RULE_REPORTS').generate(),
            authRequired: true
          }
        },
        {
          path: 'lo-transaction-reports',
          name: 'LO Transaction Reports',
          component: () => import('@/views/apps/lo/TransactionReports.vue'),
          meta: {
            rule: new AclRule('RULE_ADMIN').or('RULE_REPORTS').generate(),
            authRequired: true
          }
        },
        {
          path: 'activity-monitor',
          name: 'Activity Monitor',
          component: () => import('@/views/apps/activityMonitor/ActivityMonitor.vue'),
          meta: {
            rule: new AclRule('RULE_ADMIN').or('RULE_REPORTS').generate(),
            authRequired: true
          }
        },

        {
          path: '/spv-list',
          name: 'spv-list',
          component: () => import('@/views/apps/spv/SpvList.vue'),
          meta: {
            rule: new AclRule('RULE_ADMIN').generate(),
            authRequired: true
          }
        },
        {
          path: '/spv-view/:id',
          name: 'spv-view',
          component: () => import('@/views/apps/spv/SpvView.vue'),
          meta: {
            rule: new AclRule('RULE_ADMIN').generate(),
            authRequired: true
          }
        },
        {
          path: '/spv-edit/:id',
          name: 'spv-edit',
          component: () => import('@/views/apps/spv/SpvEdit.vue'),
          meta: {
            rule: new AclRule('RULE_ADMIN').generate(),
            authRequired: true
          }
        },

        {
          path: '/loan-originators',
          name: 'loan-originators',
          component: () => import('@/views/apps/loanOriginators/loanOriginatorsList.vue'),
          meta: {
            rule: new AclRule('RULE_ADMIN').or('RULE_LO_MANAGER').generate(),
            authRequired: true
          }
        },
        {
          path: '/loan-originators/view/:id',
          name: 'loan-originator-view',
          component: () => import('@/views/apps/loanOriginators/loanOriginator.vue'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Loan Originators', url: '/loan-originators'},
              {title: 'View', active: true}
            ],
            pageTitle: 'Loan Originator View',
            rule: new AclRule('RULE_ADMIN').or('RULE_LO_MANAGER').generate(),
            authRequired: true
          }
        },
        {
          path: '/filters',
          name: 'filters',
          component: () => import('@/views/apps/filter/FilterList.vue'),
          meta: {
            rule: new AclRule('RULE_ADMIN').generate(),
            authRequired: true
          }
        },
        {
          path: '/filters/view/:id',
          name: 'filter-view',
          component: () => import('@/views/apps/filter/FilterView.vue'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Filters', url: '/filters'},
              {title: 'View', active: true}
            ],
            pageTitle: 'Filter View',
            rule: new AclRule('RULE_ADMIN').generate(),
            authRequired: true
          }
        },
        {
          path: '/filters/edit/:id',
          name: 'filter-edit',
          component: () => import('@/views/apps/filter/FilterEdit.vue'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Filters', url: '/filters'},
              {title: 'Edit', active: true}
            ],
            pageTitle: 'Filter Edit',
            rule: new AclRule('RULE_ADMIN').generate(),
            authRequired: true
          }
        },
        {
          path: '/filters/edit',
          name: 'filter-new',
          component: () => import('@/views/apps/filter/FilterEdit.vue'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Filters', url: '/filters'},
              {title: 'New', active: true}
            ],
            pageTitle: 'Filter New',
            rule: new AclRule('RULE_ADMIN').generate(),
            authRequired: true
          }
        },
        {
          path: '/filter-rules',
          name: 'filter-rules',
          component: () => import('@/views/apps/filter/filter-rule/FilterRuleList.vue'),
          meta: {
            rule: new AclRule('RULE_ADMIN').generate(),
            authRequired: true
          }
        },
        {
          path: '/custom-filters',
          name: 'custom-filters',
          component: () => import('@/views/apps/filter/custom-filter/CustomFilterList.vue'),
          meta: {
            rule: new AclRule('RULE_ADMIN').generate(),
            authRequired: true
          }
        },
        {
          path: '/custom-filters/edit/:id',
          name: 'custom-filter-edit',
          component: () => import('@/views/apps/filter/custom-filter/CustomFilterEdit.vue'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Custom Filters', url: '/custom-filters'},
              {title: 'Edit', active: true}
            ],
            pageTitle: 'Filter Edit',
            rule: new AclRule('RULE_ADMIN').generate(),
            authRequired: true
          }
        },
        {
          path: '/custom-filters/edit',
          name: 'custom-filter-new',
          component: () => import('@/views/apps/filter/custom-filter/CustomFilterEdit.vue'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Custom Filters', url: '/custom-filters'},
              {title: 'New', active: true}
            ],
            pageTitle: 'Custom Filter New',
            rule: new AclRule('RULE_ADMIN').generate(),
            authRequired: true
          }
        },
        {
          path: '/filter-rule-comparisons',
          name: 'filter-rule-comparisons',
          component: () => import('@/views/apps/filter/filter-rule-comparison/FilterRuleComparisonList.vue'),
          meta: {
            rule: new AclRule('RULE_ADMIN').generate(),
            authRequired: true
          }
        },
        {
          path: '/filter-rule-comparisons/edit/:id',
          name: 'filter-rule-comparison-edit',
          component: () => import('@/views/apps/filter/filter-rule-comparison/FilterRuleComparisonEdit.vue'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Filter Rule Combinatios', url: '/filter-rule-comparisons'},
              {title: 'Edit', active: true}
            ],
            pageTitle: 'Filter Rule Comparison Edit',
            rule: new AclRule('RULE_ADMIN').generate(),
            authRequired: true
          }
        },
        {
          path: '/filter-rule-comparisons/edit',
          name: 'filter-rule-comparison-new',
          component: () => import('@/views/apps/filter/filter-rule-comparison/FilterRuleComparisonEdit.vue'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Filter Rule Combinatios', url: '/filter-rule-comparisons'},
              {title: 'New', active: true}
            ],
            pageTitle: 'Filter Rule Comparison New',
            rule: new AclRule('RULE_ADMIN').generate(),
            authRequired: true
          }
        },
        {
          path: '/kyc',
          name: 'kyc',
          component: () => import('@/views/apps/kyc/KycList.vue'),
          meta: {
            rule: new AclRule('RULE_ADMIN').or('RULE_KYC').generate(),
            authRequired: true
          }
        },
        {
          path: '/bank-accounts',
          name: 'bank-accounts',
          component: () => import('@/views/apps/bankAccount/BankAccountList.vue'),
          meta: {
            rule: new AclRule('RULE_ADMIN').generate(),
            authRequired: true
          }
        },
        {
          path: '/bank-accounts/view/:id',
          name: 'bank-account-view',
          component: () => import('@/views/apps/bankAccount/BankAccountView.vue'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Bank Accounts', url: '/bank-accounts'},
              {title: 'View', active: true}
            ],
            pageTitle: 'Bank Account',
            rule: new AclRule('RULE_ADMIN').generate(),
            authRequired: true
          }
        },
        {
          path: '/bank-accounts/edit/:id',
          name: 'bank-account-edit',
          component: () => import('@/views/apps/bankAccount/BankAccountEdit.vue'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Bank Accounts', url: '/bank-accounts'},
              {title: 'Edit', active: true}
            ],
            pageTitle: 'Bank Account Edit',
            rule: new AclRule('RULE_ADMIN').generate(),
            authRequired: true
          }
        },
        {
          path: '/bank-accounts/edit',
          name: 'bank-account-new',
          component: () => import('@/views/apps/bankAccount/BankAccountEdit.vue'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Bank Accounts', url: '/bank-accounts'},
              {title: 'New', active: true}
            ],
            pageTitle: 'Bank Account New',
            rule: new AclRule('RULE_ADMIN').generate(),
            authRequired: true
          }
        },
        {
          path: '/ledger-accounts',
          name: 'ledger-accounts',
          component: () => import('@/views/apps/ledgerAccount/LedgerAccountList.vue'),
          meta: {
            rule: new AclRule('RULE_ADMIN').generate(),
            authRequired: true
          }
        },
        {
          path: '/export-bank-transfers',
          name: 'export-bank-transfers',
          component: () => import('@/views/apps/exportBankTransfer/ExportBankTransferList.vue'),
          meta: {
            rule: new AclRule('RULE_ADMIN').or('RULE_BANK').generate(),
            authRequired: true
          }
        },
        {
          path: '/export-bank-transfers/view/:id',
          name: 'export-bank-transfers-view',
          component: () => import('@/views/apps/exportBankTransfer/ExportBankTransferView.vue'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Export Bank Transfers', url: '/export-bank-transfers'},
              {title: 'View', active: true}
            ],
            pageTitle: 'Bank Export',
            rule: new AclRule('RULE_ADMIN').or('RULE_BANK').generate(),
            authRequired: true
          }
        },
        {
          path: '/export-bank-transfers/files/:id',
          name: 'export-bank-transfers-files',
          component: () => import('@/views/apps/exportBankTransfer/ExportBankTransferFiles.vue'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Export Bank Transfers', url: '/export-bank-transfers'},
              {title: 'Files', active: true}
            ],
            pageTitle: 'Bank Export Files',
            rule: new AclRule('RULE_ADMIN').or('RULE_BANK').generate(),
            authRequired: true
          }
        },
        {
          path: '/postback',
          name: 'postback',
          component: () => import('@/views/apps/postback/PostBackList.vue'),
          meta: {
            rule: new AclRule('RULE_ADMIN').generate(),
            authRequired: true
          }
        },
        {
          path: '/postback/edit/:id',
          name: 'postback-edit',
          component: () => import('@/views/apps/postback/PostBackEdit.vue'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'PostBack Triggers', url: '/postback'},
              {title: 'Edit', active: true}
            ],
            pageTitle: 'PostBack Trigger Edit',
            rule: new AclRule('RULE_ADMIN').generate(),
            authRequired: true
          }
        },
        {
          path: '/postback/edit',
          name: 'postback-new',
          component: () => import('@/views/apps/postback/PostBackEdit.vue'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'PostBack Triggers', url: '/postback'},
              {title: 'Edit', active: true}
            ],
            pageTitle: 'PostBack Trigger New',
            rule: new AclRule('RULE_ADMIN').generate(),
            authRequired: true
          }
        },
        {
          path: '/lo-clearing',
          name: 'lo-clearing',
          component: () => import('@/views/apps/clearing/List.vue'),
          meta: {
            rule: new AclRule('RULE_ADMIN').or('RULE_FINANCE').generate(),
            authRequired: true
          }
        },
        {
          path: '/autoinvest-strategy',
          name: 'autoinvest-strategy',
          component: () => import('@/views/apps/autoinvest/strategy/StrategyList.vue'),
          meta: {
            rule: new AclRule('RULE_ADMIN').generate(),
            authRequired: true,
            pageTitle: 'Auto-invest Strategy'
          }
        },
        {
          path: '/autoinvest-strategy/view/:id',
          name: 'autoinvest-strategy-view',
          component: () => import('@/views/apps/autoinvest/strategy/StrategyView.vue'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Auto-invest Strategy', url: '/autoinvest-strategy'},
              {title: 'View', active: true}
            ],
            pageTitle: 'Auto-invest Strategy',
            rule: new AclRule('RULE_ADMIN').generate(),
            authRequired: true
          }
        },
        {
          path: '/mail-templates',
          name: 'mail-templates',
          component: () => import('@/views/apps/mailTemplate/MailTemplateList.vue'),
          meta: {
            rule: new AclRule('RULE_ADMIN').or('RULE_MARKETING').generate(),
            authRequired: true,
            pageTitle: 'Mail templates',
          }
        },
        {
          path: '/investor-bonuses',
          name: 'investor-bonuses',
          component: () => import('@/views/apps/investor-bonus/PendingList.vue'),
          meta: {
            rule: new AclRule('RULE_ADMIN').or('RULE_FINANCE_CONFIRM').or('R_FINANCE').generate(),
            authRequired: true,
            pageTitle: 'Investor bonuses',
          }
        },
        {
          path: '/investor-bonuses/confirmed',
          name: 'investor-bonuses-confirmed',
          component: () => import('@/views/apps/investor-bonus/ConfirmedList.vue'),
          meta: {
            rule: new AclRule('RULE_ADMIN').or('RULE_FINANCE_CONFIRM').or('R_FINANCE').generate(),
            authRequired: true,
            pageTitle: 'Confirmed investor bonuses',
          }
        },
        {
          path: '/active-campaign',
          name: 'active-campaign',
          component: () => import('@/views/apps/activeCampaign/index.vue'),
          meta: {
            rule: new AclRule('RULE_ADMIN').or('RULE_FINANCE_CONFIRM').or('R_FINANCE').generate(),
            authRequired: true,
            pageTitle: 'Active campaign management',
          }
        },
        /*
        {
          path: '/placeholders',
          name: 'Placeholders',
          component: () => import('@/views/apps/investor/investor-list/InvestorList.vue'),
          meta: {
            rule: 'editor'
          }
        },
        */
      ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      meta: {
        rule: 'RULE_GENERAL'
      },
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: 'auth0/callback',
          name: 'auth-callback',
          component: () => import('@/views/Callback.vue'),
          meta: {
            rule: new AclRule('investor').generate(),
          }
        },
        {
          path: '/login-old',
          name: 'page-login-old',
          component: () => import('@/views/pages/login/Login.vue'),
          meta: {
            rule: new AclRule('investor').generate(),
          }
        },
        {
          path: '/login',
          name: 'page-login',
          component: () => import('@/views/pages/login/Login-new.vue'),
          meta: {
            rule: new AclRule('investor').generate(),
          }
        },
        // TODO: remove from backoffice
        // {
        //   path: '/register',
        //   name: 'page-register',
        //   component: () => import('@/views/pages/login/Register-new.vue'),
        //   meta: {
        //     rule: new AclRule('investor').generate(),
        //   }
        // },
        {
          path: '/pages/error-404',
          name: 'page-error-404',
          component: () => import('@/views/pages/Error404.vue'),
          meta: {
            rule: new AclRule('investor').generate(),
          }
        },
        {
          path: '/pages/error-500',
          name: 'page-error-500',
          component: () => import('@/views/pages/Error500.vue'),
          meta: {
            rule: new AclRule('investor').generate(),
          }
        },
        {
          path: '/pages/not-authorized',
          name: 'page-not-authorized',
          component: () => import('@/views/pages/NotAuthorized.vue'),
          meta: {
            rule: new AclRule('investor').generate(),
          }
        },
        {
          path: '/pages/maintenance',
          name: 'page-maintenance',
          component: () => import('@/views/pages/Maintenance.vue'),
          meta: {
            rule: new AclRule('investor').generate(),
          }
        }
      ]
    },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: '/pages/error-404'
    }
  ]
})

router.afterEach(() =>
{
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading)
  {
    appLoading.style.display = 'none'
  }
})

router.beforeEach((to, from, next) =>
{
  firebase.auth().onAuthStateChanged(() =>
  {

    // If auth required, check login. If login fails redirect to login page
    if (to.meta.authRequired)
    {
      console.log('isAuthenticated', auth.isAuthenticated());
      if (!(auth.isAuthenticated()))
      {
        router.push({path: 'login', query: {to: to.path}})
      }
    }

    return next()
  })

})

export default router

export default {
  SET_LOAN_ORIGINATORS(state, loanOriginators)
  {
    state.loanOriginators = loanOriginators.map((item) => {
      item.status_name = Boolean(item.lo_status) ? "Active" : "Inactive";
      return item
    })
  },
  UPDATE_LOAN_ORIGINATOR_DETAILS(state, item)
  {
    const itemIndex = state.loanOriginators.findIndex((i) => i.loan_originator_id === item.loan_originator_id)
    if (itemIndex !== -1)
    {
      Object.assign(state.loanOriginators[itemIndex], item)
    }
  },
  ADD_LOAN_ORIGINATOR(state, item)
  {
    state.loanOriginators.push(item);
  }
}

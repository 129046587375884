export default {
  custom: {
    borrower_name: {
      required: 'Please enter borrower name',
      //alpha: 'Your first name may only contain alphabetic characters'
    },
    product_name: {
      required: 'Product name is required',
      //alpha: 'Your first name may only contain alphabetic characters'
    },
  }
}

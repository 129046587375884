/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
  ADD_PRODUCT (state, item) {
    state.products.unshift(item)
  },
  SET_PRODUCTS (state, products) {
    state.products = products
  },
  UPDATE_PRODUCT (state, product) {
    const productIndex = state.products.findIndex((p) => p.id === product.id)
    if (productIndex !== -1)
    {
      Object.assign(state.products[productIndex], product)
    }
  }
}

/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from '@/axios.js'

export default {
  addProduct ({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.post('/api/backoffice/products/', item)
        .then((response) => {
          commit('ADD_PRODUCT', Object.assign(item, {id: response.data.id}))
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchProducts ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/backoffice/products')
        .then((response) => {
          commit('SET_PRODUCTS', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateProduct ({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.put(`/api/backoffice/products/${item.id}`, item)
        .then((response) => {
          commit('UPDATE_PRODUCT', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}

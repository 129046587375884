import axios from '@/axios.js'

export default {
  fetchInvestors ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/backoffice/investors')
        .then((response) => {
          commit('SET_INVESTORS', response.data)
          resolve(response)
        })
        .catch((error) => {reject(error) })
    })
  },
  fetchInvestor ({ commit }, investorId) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/backoffice/investor-details/${investorId}`)
        .then((response) => {
          commit('UPDATE_INVESTOR_DETAILS', response.data)
          resolve(response)
        })
        .catch((error) => {reject(error) })
    })
  },
  updateInvestor ({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.put(`/api/backoffice/investors/${item.id}`, item)
        .then((response) => {
          commit('UPDATE_INVESTOR_DETAILS', response.data)
          resolve(response)
        })
        .catch((error) => {reject(error) })
    })
  }
}

import axios from '@/axios.js'

export default {
  fetchLoanOriginators ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/backoffice/loan-originators')
        .then((response) => {
          if (response && response.data && response.data.success)
          {
            commit('SET_LOAN_ORIGINATORS', response.data.data)
            resolve(response)
          }
        })
        .catch((error) => {reject(error) })
    })
  },
  fetchLoanOriginator ({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/backoffice/loan-originators/${id}`)
        .then((response) => {
          if (response && response.data && response.data.success)
          {
            if (!response.data.data.address) {
              response.data.data.address = {};
            }

            if (response.data.data.person && !response.data.data.person.identification) {
              response.data.data.person.identification = {};
            }

            commit('UPDATE_LOAN_ORIGINATOR_DETAILS', response.data.data)
            resolve(response.data.data)
          }
        })
        .catch((error) => {reject(error) })
    })
  },
  generateLoanOriginatorApiKey ({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/backoffice/loan-originators/generate_apikey/${id}`)
        .then((response) => {
          if (response && response.data && response.data.success)
          {
            commit('UPDATE_LOAN_ORIGINATOR_DETAILS', {
              loan_originator_id: id,
              api_key: response.data.data
            });

            resolve(response.data.data)
          }
        })
        .catch((error) => {reject(error) })
    })
  },
  createLoanOriginator({commit}, item)
  {
    return new Promise((resolve, reject) =>
    {
      axios.post(`/api/backoffice/loan-originators`, item)
        .then((response) =>
        {
          if (!response.status && response.response) {
            response = response.response
          }

          if (response && response.data && response.data.success)
          {
            commit('ADD_LOAN_ORIGINATOR', response.data.data)
            resolve(response.data.data)
          } else {
            reject(response.data)
          }
        })
        .catch((error) =>
        {
          reject(error)
        })
    })
  },
  updateLoanOriginator ({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.put(`/api/backoffice/loan-originators/${item.loan_originator_id}`, item)
        .then((response) => {
          if (response.request && response.request.status === 424)
          {
            const respObj = JSON.parse(response.request.response);
            reject(respObj.message)
          }
          if (response && response.data && response.data.success)
          {
            commit('UPDATE_LOAN_ORIGINATOR_DETAILS', response.data.data)
            resolve(response.data.data)
          }
        })
        .catch((error) => { reject(error) })
    })
  },
  updateLoanOriginatorLoansSettings ({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.put(`/api/backoffice/loan-originators/loans_settings/${data.loId}`, data.loansSettings)
        .then((response) => {
          if (response && response.data && response.data.success)
          {
            resolve(response.data.data)
          }
        })
        .catch((error) => {reject(error) })
    })
  },
  deleteLoanOriginatorLoansSettings ({ commit }, id) {
    return new Promise((resolve, reject) => {
      if (!id)
      {
        return resolve({})
      }
      axios.delete(`/api/backoffice/loan-originators/loans_settings/${id}`)
        .then((response) =>
        {
          if (response && response.data && response.data.success)
          {
            resolve(response.data.data)
          }
        })
        .catch((error) =>
        {
          reject(error)
        })
    })
  },
  fetchLOAPIMethodsList ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/backoffice/loan-originators/api_methods_list`)
        .then((response) => {
          if (response && response.data && response.data.success)
          {
            resolve(response.data.data)
          }
        })
        .catch((error) => {reject(error) })
    })
  },
}

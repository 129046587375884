
import state from './moduleLoanOriginatorsState.js'
import mutations from './moduleLoanOriginatorsMutations.js'
import actions from './moduleLoanOriginatorsActions.js'
import getters from './moduleLoanOriginatorsGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}


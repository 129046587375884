import state from './moduleReferralCodeManagementState.js'
import mutations from './moduleReferralCodeManagementMutations'
import actions from './moduleReferralCodeManagementActions'
import getters from './moduleReferralCodeManagementGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}


import axios from '@/axios.js'

export default {
  fetchClassificators({commit})
  {
    return new Promise((resolve, reject) =>
    {
      if (this.state.classificatorManagement.is_loaded)
      {
        resolve()
        console.log('classificators CACHED already');
      }
      else
      {
        axios.get('/api/backoffice/classificators')
          .then((response) =>
          {
            commit('SET_CLASSIFICATORS', response.data)
            resolve(response)
          })
          .catch((error) =>
          {
            reject(error)
          })
      }
    })
  }
}


export default {
  SET_INVESTORS (state, investors) {
    if (investors.items) {
      investors.items = investors.items.map((investor) => {
        investor.display_name = investor.investor_full_name || investor.investor_email;
        return investor;
      });
    }

    state.investors = investors;
  },
  UPDATE_INVESTOR_DETAILS (state, item) {
    const itemIndex = state.investors.findIndex((i) => i.id === item.id)
    if (itemIndex !== -1)
    {
      Object.assign(state.investors[itemIndex], item)
    }
  }
}

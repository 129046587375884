// axios
import axios from 'axios'
import auth from '@/auth/authService'

const baseURL = ''

const instance = axios.create({
  baseURL
  // You can add your headers here
})

instance.interceptors.response.use(response =>
{
  return response;
}, error =>
{
  if (error.response && error.response.status === 401)
  {
    auth.logOut();
  }
  return error;
});

instance.CancelToken = axios.CancelToken
instance.isCancel = axios.isCancel

export default instance

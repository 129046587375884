export default {
  SET_CLASSIFICATORS(state, classificators)
  {
    let i;
    for (i = 0; i < classificators.length; i++)
    {
      if (!state.classificators[classificators[i]['group_name']])
      {
        state.classificators[classificators[i]['group_name']] = []; // init group name
      }
      state.classificators[classificators[i]['group_name']].push(classificators[i])
    }
    state.is_loaded = true
  }
}

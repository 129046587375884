export default {
  ADD_REFERRAL_CODE (state, item) {
    state.referralCodes.unshift(item)
  },
  SET_REFERRAL_CODES (state, referralCodes) {
    state.referralCodes = referralCodes
  },
  UPDATE_REFERRAL_CODE (state, referralCode) {
    const referralCodeIndex = state.referralCodes.findIndex((p) => p.id === referralCode.id)
    if (referralCodeIndex !== -1)
    {
      Object.assign(state.referralCodes[referralCodeIndex], referralCode)
    }
  },
  ADD_UTM_TAG_BONUS (state, utmTag) {
    state.utmTagBonuses.push(utmTag)
  },
  SET_UTM_TAG_BONUS (state, utmTags) {
    state.utmTagBonuses = utmTags;
  },
  REMOVE_UTM_TAG_BONUS (state, utmTag) {
    state.utmTagBonuses.splice(
      state.utmTagBonuses.findIndex((p) => p.id === utmTag.id),
      1
    );
  }
}
